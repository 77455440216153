<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-pencil"
        inline
        :title="`Edit ${label} Project`"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-card-text v-else>
        <div v-if="canAccess({module: 'saas', method: 'get', route: '/project'})" class="text-right">
          <v-btn small color="warning lighten-2"
                 @click="$router.push({name: type === 'saas' ? 'saaSProjects': 'enterpriseProjects' })">
            <v-icon class="mr-2">mdi-arrow-left-circle</v-icon>
            Back
          </v-btn>
        </div>
        <v-container v-if="project.status === 'ready'">
          <form @submit="checkForm" novalidate="true">
            <v-row>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">

                <v-text-field
                    prepend-icon="mdi-barcode"
                    color="secondary"
                    label="Project Name..."
                    v-model="project.name"
                />

                <v-text-field
                    prepend-icon="mdi-text-short"
                    color="secondary"
                    label="Description..."
                    v-model="project.description"
                />

                <v-select
                    :items="paymentMethods"
                    label="Payment Method"
                    prepend-icon="mdi-credit-card"
                    v-model="project.paymentMethod"
                    item-text="name"
                    item-value="id"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
                  </template>

                  <template v-slot:item="{item}">
                    {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
                  </template>

                  <template v-slot:no-data>
                    <p class="py-2 px-3">No Payment Methods Found! Please add a Credit Card to proceed,
                      <router-link :to="{'name': 'addPayment'}">Click Here</router-link>
                      .
                    </p>
                  </template>
                </v-select>

                <v-select @change="changeType()"
                          :items="productTypes"
                          :label="type ==='saas' ? 'Package' : 'License Type'"
                          prepend-icon="mdi-layers"
                          v-model="project.productType.id"
                          item-text="description"
                          item-value="id"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.productInfo.name }}
                  </template>

                  <template v-slot:item="{item}">
                    {{ item.productInfo.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="project.productType">
                <v-simple-table dense v-if="type ==='saas' && project.productType.productInfo">
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td colspan="2" class="text-center mb-6" :style="{'border-bottom':'none'}">
                        <h3>{{ project.productType.productInfo.name }}</h3>
                        <p class="text-muted" v-if="project.productType.productInfo.description">
                          {{ project.productType.productInfo.description }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left primary white--text font-weight-bold" width="30%">
                        vCPU
                      </td>
                      <td class="text-right">{{ project.productType.productInfo.metadata.vCPU }}</td>
                    </tr>
                    <tr>
                      <td class="text-left primary white--text font-weight-bold">
                        RAM
                      </td>
                      <td class="text-right">{{ project.productType.productInfo.metadata.RAM }}</td>
                    </tr>
                    <tr>
                      <td class="text-left primary white--text font-weight-bold">
                        Storage
                      </td>
                      <td class="text-right">{{ project.productType.productInfo.metadata.Storage }}</td>
                    </tr>
                    <tr>
                      <td class="text-left primary white--text font-weight-bold">
                        IOPS
                      </td>
                      <td class="text-right">{{ project.productType.productInfo.metadata.IOPS }}</td>
                    </tr>
                    <tr>
                      <td class="text-left primary white--text font-weight-bold">
                        Connections
                      </td>
                      <td class="text-right" colspan="2">{{
                          project.productType.productInfo.metadata.connections
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left error white--text font-weight-bold">
                        Price&nbsp;/&nbsp;{{ project.productType.recurring.interval }}
                      </td>
                      <td class="text-right text-uppercase">
                        <v-chip label color="error" class="mt-1 mb-1">
                          <vue-numeric
                              class="d-inline"
                              output_type="string"
                              currency="$"
                              separator=","
                              v-bind:precision="2"
                              :read-only="true"
                              v-model="project.productType.display_unit_amount">
                          </vue-numeric>
                        </v-chip>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <template v-if="type === 'enterprise'">
                  <v-card
                      flat
                      class="pt-1"
                      v-if="project.productType.productInfo"
                  >
                    <v-card-title class="mb-3 accent--text">
                      <v-icon class="mr-2 accent--text">mdi-server</v-icon>
                      {{ project.productType.name }}
                    </v-card-title>
                    <v-card-text>
                      {{ project.productType.description }}
                    </v-card-text>
                    <v-simple-table class="pl-2 pb-4">
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <td class="text-left error--text font-weight-bold">
                            Price&nbsp;/&nbsp;{{ project.productType.recurring.interval }}
                          </td>
                          <td class="text-right text-uppercase">
                            <v-chip label color="error" class="mt-1 mb-1">
                              <vue-numeric
                                  class="d-inline"
                                  output_type="string"
                                  currency="$"
                                  separator=","
                                  v-bind:precision="2"
                                  :read-only="true"
                                  v-model="project.productType.display_unit_amount">
                              </vue-numeric>
                            </v-chip>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </form>
        </v-container>
        <v-container v-else>
          <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
            <h2>Project is still not ready</h2>
            <p>
              This project is still being updated and will become ready soon. <br/>
              Once it is updated and ready, you can resume work and download its files.
            </p>
          </v-alert>
        </v-container>

        <v-dialog
            v-model="checkDialog"
            width="70%"
        >
          <v-card>
            <v-card-title class="text-h2 white--text pb-3" :style="{'background-color': '#2E9EF2'}">
              Important Update Notice
            </v-card-title>

            <v-card-text class="text-left">
              <h4 class="my-4">You are about to edit this project. Before proceeding, please read the below:</h4>
              <template v-if="type === 'saas'">
                <h5>Package Changed</h5>
                <ol>
                  <li>The platform requires between 3 - 10 minutes to spin up a new Database cluster based on the
                    package you have selected.
                  </li>
                  <li>During the update, your project remains operational, but the Console will not be accessible until
                    the update procedure has completed.
                  </li>
                  <li>
                    Once the update finishes:
                    <ol>
                      <li>The console becomes accessible for this project.</li>
                      <li>The platform will provide all the gateway instances of this project with the details of the
                        newly created cluster.
                      </li>
                      <li>The platform will automatically restart all the gateway instances you have running for this
                        project.
                      </li>
                    </ol>
                  </li>
                </ol>
                <!-- todo: eliane, please add v-if the current project has free trial enabled then show the below -->
                <template v-if="project.subscription.status ==='trialing'">
                  <br>
                  <hr/>
                  <v-alert type="warning" outlined border="left" class="mt-5 py-3">
                    <h2>Free Trial will Terminate</h2>
                    <p>
                      You are upgrading a project that is currently on FREE trial.
                      If you proceed, the free trial will
                      end and will no longer be available; not even for other projects.
                    </p>
                  </v-alert>
                </template>
              </template>
              <template v-else>
                <h5>License Changed</h5>
                <p>
                  The platform generates a new LICENSE file for your enterprise edition.<br/>
                  Download this new LICENSE file and replace the one you already have with it.
                </p>
              </template>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                  color="error"
                  @click="cancelEdit">
                <v-icon class="mr-2">mdi-cancel</v-icon>
                Cancel
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                  color="success"
                  @click="continueEdit"
              >
                <v-icon class="mr-2">mdi-thumb-up-outline</v-icon>
                Approve
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAccess({module: 'saas', method: 'post', route: '/project/:type'})"
            right
            color="secondary"
            @click="checkForm"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
            right
            color="error"
            @click="resetForm"
        >
          <v-icon class="mr-1">mdi-cancel</v-icon>
          Reset
        </v-btn>
      </v-card-actions>
    </base-material-card>

  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import VueNumeric from "vue-numeric";
import axios from "axios";

const data = {
  checkDialog: false,
  loading: true,
  originalCopy: null,
  paymentMethods: [],
  productTypes: [],
  project: {
    name: '',
    description: '',
    paymentMethod: '',
    productType: {}
  }
};

const methods = {

  async initialize() {
    this.loading = true;

    setTimeout(async () => {
      await this.getData();
    }, 500);

  },

  async getData() {

    const self = this;
    await self.getSendData({
      url: '/saas/paymentMethod',
      method: 'get',
      params: {}
    }).then(response => {
      self.paymentMethods = response.rows;
    }).catch(error => {
      this.handleErrorResponse(error);
    });

    await self.getSendData({
      url: '/saas/productPrice',
      method: 'get',
      params: {
        'type': self.type
      }
    }).then(response => {
      self.productTypes = response.rows;
      self.productTypes.forEach((oneProductType) => {
        oneProductType.display_unit_amount = oneProductType.unit_amount / 100;
      });
    }).catch(error => {
      this.handleErrorResponse(error);
    });

    await self.getSendData({
      url: `/saas/project/${self.id}`,
      method: 'get',
      params: {
        'type': self.type
      }
    }).then(response => {
      self.paymentMethods.forEach((onePaymentMethod) => {
        if (onePaymentMethod.id === response.subscription.paymentMethod) {
          response.paymentMethod = onePaymentMethod.id;
        }
      });

      response.productType = {};
      self.productTypes.forEach((oneProductType) => {
        if (oneProductType.id === response.subscription.plan.id) {
          response.productType = {...oneProductType};
        }
      });

      self.originalCopy = this._lodash.cloneDeep(response);
      self.project = {...response};

    }).catch(error => {
      this.handleErrorResponse(error);
    });

    this.loading = false;
  },

  changeType() {
    const self = this;
    this.productTypes.forEach((oneProductType) => {
      if (oneProductType.id === self.project.productType.id) {
        self.project.productType = {...oneProductType};
      }
    });
  },

  checkForm() {
    const self = this;
    if (this.originalCopy.status !== 'ready') {
      return;
    }
    if (!this.project.name || this.project.name.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please enter the name of the project!'
      });
      return false;
    }

    if (!this.project.description || this.project.description.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please enter the project description!'
      });
      return false;
    }

    if (!this.project.paymentMethod) {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please select a payment method to proceed!'
      });
      return false;
    }

    if (!this.project.productType) {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please select the size of the product you want to create!'
      });
      return false;
    }

    self.editProject();
  },

  resetForm() {
    this.project = {...this.originalCopy};
  },

  continueEdit() {
    const self = this;
    this.checkDialog = false;

    function updateInfo() {
      if (self.project.description === self.originalCopy.description) {
        if (self.project.name === self.originalCopy.name) {
          return true;
        }
      }
      const options = {
        noLoading: true,
        url: `/saas/project/${self.project.id}/description`,
        method: 'put',
        params: {
          name: self.project.name,
          description: self.project.description
        }
      };

      return self.getSendData(options).then(response => {
        return true;
      }).catch(error => {
        throw error;
      });
    }

    function updatePayment() {
      if (self.project.paymentMethod === self.originalCopy.paymentMethod) {
        return true;
      }
      return self.getSendData({
        noLoading: true,
        url: `/saas/project/${self.project.id}/paymentMethod`,
        method: 'put',
        params: {
          paymentMethod: self.project.paymentMethod
        }
      }).then(response => {
        return true;
      }).catch(error => {
        throw error;
      });
    }

    function updatePlan() {
      if (self.project.productType.id === self.originalCopy.productType.id) {
        return true;
      }
      const options = {
        noLoading: true,
        url: `/saas/project/${self.project.id}/updatePlan`,
        method: 'put',
        params: {
          priceId: self.project.productType.id
        }
      };
      return self.getSendData(options)
          .then(response => {
            return true;
          }).catch(error => {
            throw error;
          });
    }

    self.$emit('triggerOverlayLoading', true);
    axios.all([updateInfo(), updatePayment(), updatePlan()])
        .then(() => {
          self.$emit('triggerOverlayLoading', false);
          if (self.type === 'saas' && (self.project.productType.id !== self.originalCopy.productType.id)) {
            self.pushMessage({
              type: 'success',
              title: 'Project Updated in progress',
              text: "Your project update is in progress. You will be notified by email once the project is ready"
            });
            self.project.status = 'pending';
            setTimeout(() => {
              self.$router.push({name: self.type === 'saas' ? 'saaSProjects' : 'enterpriseProjects'});
            }, 5000);
          } else {
            self.pushMessage({
              type: 'success',
              title: 'Project Updated',
              text: `Project ${self.project.name} has been updated.`
            });
            // self.resetForm();
            self.$emit('triggerOverlayLoading', false);
            setTimeout(() => {
              self.$router.push({name: self.type === 'saas' ? 'saaSProjects' : 'enterpriseProjects'});
            }, 5000);
          }
          this.scrollToTop();
        })
        .catch(error => {
          self.$emit('triggerOverlayLoading', false);
          //this.handleErrorResponse( error );
        });
  },

  cancelEdit() {
    this.checkDialog = false;
  },

  editProject() {
    const self = this;
    if (self._lodash.isEqual(self.originalCopy, self.project)) {
      self.pushMessage({
        type: 'success',
        title: '',
        text: `Nothing to update`
      });
      return;
    }

    if (self.project.productType.id === self.originalCopy.productType.id) {
      this.continueEdit();
      return;
    }
    if (self.type === 'enterprise') {
      this.continueEdit();
      return;
    }
    // in case of saas project, and change in payment plan
    this.checkDialog = true;
    return;
  }
};

export default {
  name: 'editProject',
  methods: methods,
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['saas', 'enterprise'].includes(value);
      },
    },
    enterpriseFeatures: {
      type: Array
    },
    saasFeatures: {
      type: Array
    },
    id: String,
  },
  components: {
    VueNumeric
  },

  computed: {
    label: function () {
      return (this.type === 'saas') ? 'SAAS' : 'Enterprise';
    }
  },

  mixins: [globalMixins],

  async created() {
    this.clearMessages();
    setTimeout(() => {
      this.initialize();
    }, 500);
  },

  data: () => (data),
}
</script>
